:root {
  --color-fondo: #49586b;
  --color-fondo-dark: #14283f;
  --color-contenedor: #14202e;
  --color-componentes-dark: #0a141f;
  --color-campos: #293a4d;
  --texto-resaltado: #ffa834;
  --texto-silenciado: #a8c6fa;
  --texto-secundario: #3d8af7;
  --velocidad-scroll: 15s;
  --velocidad-scroll-tags: 10s;
  --scroll-gap: 2rem;
  --grid-gap: 1.5rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  min-height: 100vh;
}

body {
  background: linear-gradient(0deg, var(--color-fondo-dark) 0%, var(--color-fondo) 100%);
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dark {
  background-color: var(--color-componentes-dark);
}

.texto-resaltar {
  color: var(--texto-resaltado);
}

.texto-silenciado {
  color: var(--texto-silenciado);
}

.texto-secundario {
  color: var(--texto-secundario);
}

.contenedor {
  align-items: center;
  background-color: var(--color-contenedor);
  border-image: radial-gradient( white, var(--color-contenedor));
  border-image-slice: 1;
  border-width: 2px;
  border-style: solid;
  max-width: 900px;
  min-height: 100vh;
}

.form-control:not(:active),
input[type="text"]:focus,
input[type="email"]:focus,
textarea[type="text"]:focus {
  background-color: var(--color-campos);
  color: white;
}

input[type="text"]:disabled,
input[type="email"]:disabled,
textarea[type="text"]:disabled {
  background-color: var(--color-contenedor);
}

input::placeholder {
  color: white;
}
input::-ms-input-placeholder {
  color: white;
}

.img-btn:hover {
  scale: 1.3;
  cursor: pointer;
  transition: 0.2s;
}

.habilidades {
  mask-image: linear-gradient(90deg, transparent, #fff, 40%, #fff, 80%, transparent); 
}

.habilidades img:hover {
  scale: 1.5;
}

.proyectos ul {
  list-style: none;
  gap: var(--grid-gap);
  margin-top: var(--grid-gap);
}

.proyectos ul li {
  background-color: #14283f;
  height: 250px;
  cursor: pointer;
}

.proyectos ul li img {
  height: 100%;
  max-width: 100%;
  object-fit: cover;
  transition: all .5s;
}

.scroll,
.scroll-tags {
  display: flex;
  gap: var(--scroll-gap);
  user-select: none;
}

.scroll {
  overflow: scroll hidden;
}

.scroll-tags {
  width: fit-content;
  min-width: 100%;
}

.tags-wrapper {
  overflow-x: scroll;
}

.scroll ul,
.scroll-tags ul {
  flex-shrink: 0;
  align-items: center;
  margin: auto;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
}

.scroll ul {
  min-width: 100%;
  justify-content: space-between;
  gap: var(--scroll-gap);
}

.scroll:hover ul {
  animation-play-state: paused;
}

.scroll-tags ul {
  animation: none;
  opacity: 1;
  transition: opacity .2s;
}

.scroll-tags ul li {
  padding: 0.2rem 1rem;
  background-color: var(--color-componentes-dark);
  border-color: white;
  border-width: 1px;
  border-style: solid;
  margin-bottom: 10px;
  white-space: nowrap;
}

.contenido .scroll-tags ul li {
  margin-inline: 0.5rem;
}

.tags ul li {
  border-width: 1px;
  border-color: white;
  border-style: solid;
  background-color: var(--color-componentes-dark);
}

.card {
  background-color: #0a141f;
}

.card-text {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.card-img-overlay {
  opacity: 0;
  transition: opacity .5s;
  overflow: hidden;
  user-select: none;
  background: linear-gradient(0, black, #0000007e);
}

.modal-proyecto {
  color: white;
  background-color: rgba(0, 0, 0, 0.527);
  backdrop-filter: blur(2px);
}

.modal-proyecto .modal-proyecto-body,
.modal-proyecto .modal-proyecto-header {
  background-color: #015688;
  overflow: hidden;
}

.modal-proyecto .modal-proyecto-body .contenido {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.705) 0%, rgba(0, 0, 5, 0.637) 30%, var(--color-componentes-dark) 100%);
  width: 100%;
  height: 100%;
  position: relative;
  left: 0;
  top: 0;
}
.modal-proyecto .modal-proyecto-body img {
  position: absolute;
  object-fit: fill;
  top: -80%;
  min-width: 100%;
  min-height: 100%;
}

.modal-proyecto  .modal-proyecto-footer {
  background-color: var(--color-componentes-dark);
}

@media (prefers-reduced-motion: no-preference) {

  .tags-wrapper {
    mask-image: linear-gradient(90deg, transparent 0%, white 5%, white 90%, transparent 100%);
    overflow: hidden;
  }
  .scroll,
  .scroll-tags {
    overflow: hidden;
  }

  .scroll-tags ul {
    opacity: 0;
  }

  .scroll ul {
    animation: scroll var(--velocidad-scroll) linear infinite;
  }
  .card-img-overlay:hover .scroll-tags ul {
    animation: scroll-tags var(--velocidad-scroll-tags) linear infinite;
  }
  .modal-proyecto .scroll-tags ul {
    animation: scroll-tags var(--velocidad-scroll-tags) linear infinite;
  }

  @keyframes scroll {
    to { transform: translateX(calc(-100% - var(--scroll-gap))); }
  }
  @keyframes scroll-tags {
    0% { 
      transform: translate(0%, 0%);
      opacity: 0; 
    }
    5% { 
      transform: translate(0%, 0%);
      opacity: 1; 
    }
    100% { 
      transform: translate(-100%, 0%);
      opacity: 1; 
    }
  }
  @keyframes deslizar-contenido {
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
  @media (min-width: 500px) {
    .card:hover .card-img-overlay {
      opacity: 1;
    }
    .card:hover img {
      transform: scale(1.1);
      mask-image: linear-gradient(0, transparent 5%,  black 60%);
    } 
  }
  @media (max-width: 500px) {
    .cabecera {
      display: flex;
      min-height: 90vh;
    }
    .contenido {
      transform: scale(0);
      opacity: 0;
      padding-top: 50px;
      animation-name: deslizar-contenido;
      animation-fill-mode: forwards;
      animation-duration: 1ms;
      animation-timeline: view(block);
      animation-range: entry -100%;
    }
  }
}